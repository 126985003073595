body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainWindow{
  max-width: 1200px !important;
  border:1px dotted grey;
  box-shadow: 5px 5px 5px grey;
  padding:10px;
}
a {
  color: #003f6b !important;

}
