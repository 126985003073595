.loading-faded{
    filter:brightness(50%);
    background-color:#D8D8D8;
}

 .zoom-placement{
    right: 8px;
    left: unset;
}

.zoom-button-width {
    width: 29px
}

.coordinate-viewer {
    pointer-events: auto;
    font-size: small;
    position: absolute;
    bottom: 8px;
    left: 8px;
    background: var(--ol-partial-background-color);
}

.ol-scale-line {
    background: var(--ol-partial-background-color);
    border-radius: 4px;
    bottom: 28px;
    left: 8px;
    padding: 2px;
    position: absolute;
}

.attribution {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.dropdown {
    z-index: 100 !important;
}

.map-manager {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.map-manager-button {
    margin-bottom: 5px;
}

.basemap-selector-component {
    margin: 0;
    padding: 0.4rem;
    transform: translateY(0.2rem);
    font-size: medium;
}

.basemap-layer-component {
    margin: 0;
    padding: 0.4rem;
    transform: translateY(0.2rem);
    font-size: medium;
}

.map-manager-checkboxes{
    margin-right: 5px;
}

.tooltip-style {
    z-Index:50000;
    font-size: small;
}

.spinner-style {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.layers-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.sign-placement{
    padding-right: 60px;
    transform: scale(0.75);
}
.filter-header{
    font-weight: 500;
}
.font-to-opened-filter-selection{
    font-size: large;
}
.drop-checkbox{
    margin-bottom: 0px;
}
.flex-for-plus-and-checkbox{
    display: flex;
}
.info-overlay{
    background-color: azure;
    box-shadow: 0 1px 4px rgba(0,0,0,0,2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #D8D8D8;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
}
.layer-selector-closer{
    display: flex;
    flex-direction: row-reverse;
    padding-right: 5px;
}
.map-container{
    height: 76vh;
    width: 100%;
}

button.accordion-header,button.btn-no-style, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.no-wrap-plus-minus-signs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    white-space: nowrap;
    background: none;
    border: none;
  }
  .show-cursor {
    cursor: pointer;
  }

  .site-status-box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .destination-status-box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }