.modal-box-transparent-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;



    border-radius:5px;
    padding: 1em;
}
