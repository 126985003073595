.form-autocomplete-input{
    margin-bottom:1em;
    z-index:8;
}
.form-invalid{
    border:2px solid #EA5B0C;
}
.coverAll{
    position:fixed !important;
    top:0 !important;
    left:0 !important;
    width:100%;
    height:100%;
    z-index:9
}
.tooltip-style {
    z-Index:50000;
    font-size: small;
}
/*

input:focus{
    border:2px dotted blue !important;
}

button:focus{
    border:2px dotted blue !important;
}*/
