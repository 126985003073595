.footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.footer-link {
    font-size: small;
}
.footer-version {
    font-size: small;
}