@media screen and (max-width: 640px) {
    .desktop-only {
        display: none;
    }

    .mh-logo {
        width: 3em;
        height: 2em;
    }

    .main-header-placement {
        display: flex;
        justify-content: space-between;
    }

    .user-viewer {
        display: flex;
        align-items: center;
    }

    .user-viewer-text {
        padding-right: 5px;
        padding-top: 5px;
    }
}

@media screen and (min-width: 641px) {
    .mobile-only {
        display: none;
    }

    .mh-logo {
        width: 4.5em;
        height: 3em;
    }

    .main-header-placement {
        display: flex;
        justify-content: space-between;
    }

    .user-viewer {
        display: flex;
        align-items: center;
    }

    .user-viewer-text {
        padding-right: 5px;
        padding-top: 5px;
    }
}

.selected-tab {
    background: #024a23;
    border-color: #013b1c;
    color: #f4f2ef;
}

.hidden-skip-link {
    position: absolute;

    transform: translateY(-1000%);
}

.hidden-skip-link:focus {
    transform: translateY(0%);

}

nav > a {
    text-decoration:none !important;
}