.button-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.buttons{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
}
.tooltip-style {
    z-Index:50000;
    font-size: small;
}