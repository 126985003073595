.multiViewMainPanel{
    flex:1;
    display:flex;
    border: 1px solid black;
}

.maxHeighter{
    margin-top:40px;
    overflow:scroll;

    max-height:76vh;
    border:1px solid #D8D8D8;
}

.maxHeighter-with-buttons{
    margin-top:87px;
    overflow:scroll;

    max-height:76vh;
    border:1px solid #D8D8D8;
}

.halfHeighter{
    max-height:45vh !important;
}

.header-grid {
    display: grid;
    grid-template-columns: 30% 60% 30%;
    grid-template-rows: 100%;
    width:78%;
}

.page-name-cell {
    grid-column: 2;
    place-self: center;
}

.button-cell {
    place-self: flex-start;
}
.tooltip-style {
    z-Index:50000;
    font-size: small;
}
.div-to-center-image{
    display: flex;
    padding-top: 7px;
    padding-bottom: 7px;
}
