.visits-with-errors{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}
.dialog-warning-color{
    filter: invert(21%) sepia(100%) saturate(2157%) hue-rotate(345deg) brightness(94%) contrast(88%);
}