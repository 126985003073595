.field-invalid{
    border:2px solid #EA5B0C;
}
.tooltip-style {
    z-Index:50000;
    font-size: small;
}

/*
input:focus{
    border:2px dotted blue !important;
}

button:focus{
    border:2px dotted blue !important;
}*/


