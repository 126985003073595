

@media only screen and (min-width: 800px) {
    .standardPaginationWidth{


        min-width:3vw;
        max-width:3vw;
    }

}

@media only screen and (max-width: 799px) and (min-width: 550px) {
    .standardPaginationWidth{
        min-width:6vw;
        max-width:6vw;
    }

}
@media only screen and (max-width: 549px) {
    .standardPaginationWidth{
        min-width:4vw;
        max-width:4vw;
    }

}



.paginationContainer{
    display: flex;
    justify-content: center;

    text-align: center;
}
