.form-invalid{
    border:2px solid #EA5B0C;
}
.tooltip-style {
    z-Index:50000;
    font-size: small;
}

.info-row{
    word-break: break-word;
    max-width:20em;
}