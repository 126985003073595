.modal-box-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 24px 24px 24px;
    padding: 1em;
}
