.coverAll{
    position:fixed !important;
    top:0 !important;
    left:0 !important;
    width:100%;
    height:100%;
    z-index:9
}
.titleDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: baseline;
    align-content: center;
}